import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './public/style.css';
import Logo from './public/images/logo.png';
import { useAuth } from '../contexts/AuthContext';
import { getAuth, signOut } from "firebase/auth";
import SpaceDashboardSharpIcon from '@mui/icons-material/SpaceDashboardSharp';
import OnlinePredictionSharpIcon from '@mui/icons-material/OnlinePredictionSharp';
import EventAvailableSharpIcon from '@mui/icons-material/EventAvailableSharp';
import EventBusySharpIcon from '@mui/icons-material/EventBusySharp';
import PendingActionsSharpIcon from '@mui/icons-material/PendingActionsSharp';
import { getDatabase, ref, onValue } from "firebase/database";

const Home = ({ dashboardTitle, name }) => {
  const navigate = useNavigate();
  const { userLoggedIn } = useAuth();
  const auth = getAuth();
  const [isLidOpen, setIsLidOpen] = useState(false);
  const [isFull, setIsFull] = useState(false);
  const [isOnline, setIsOnline] = useState(false);
  const [capacity, setCapacity] = useState(null);
  const [uptime, setUptime] = useState(null);
  const [onlineCount, setOnlineCount] = useState(0);
  const [availableCount, setAvailableCount] = useState(0);
  const [fullCount, setFullCount] = useState(0);
  const maintenanceCount = "N/A";
  const [binExists, setBinExists] = useState(true);

  // Redirect to login page if user is not logged in
  useEffect(() => {
    if (!userLoggedIn) {
      navigate('/login');
    }
  }, [userLoggedIn, navigate]);

  // Get a reference to the database
  useEffect(() => {
    const db = getDatabase();
    const dataRef = ref(db);

    // Listen for changes in the data
    const unsubscribe = onValue(dataRef, (snapshot) => {
      const data = snapshot.val();

      if (userLoggedIn) {
        const email = auth.currentUser.email;
        const parsedEmail = email.replace(/@/g, '_at_').replace(/\./g, '_dot_');
        const userData = data.users?.[parsedEmail];

        if (userData && userData.bin) {
          const privateData = userData.bin;
          setBinExists(true);
          setIsLidOpen(privateData.isLidOpen);
          setIsFull(privateData.full);
          setIsOnline(privateData.Online);
          setCapacity(privateData.capacity);
          setUptime(privateData.Up_Time);

          // Calculate online, available, and full bins
          let online = 0;
          let available = 0;
          let full = 0;

          Object.values(data.users).forEach(user => {
            if (user.bin) {
              const bin = user.bin;
              available += 1; // Count each user
              if (bin.Online) online += 1;
              if (bin.full) full += 1;
            }
          });

          setOnlineCount(online);
          setAvailableCount(available);
          setFullCount(full);
        } else {
          setBinExists(false);
        }
      }
    });

    return () => unsubscribe();
  }, [auth.currentUser, userLoggedIn]);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/login');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  return (
    <div className="container">
      <aside>
        <div className="top">
          <div className="logo">
            <img src={Logo} alt="Logo" />
            <h2>SmartBin</h2>
          </div>
          <div className="close" id="close-btn">
            <span className="material-symbols-sharp">close</span>
          </div>
        </div>
        <div className="sidebar">
          <a href="#" className="active">
            <SpaceDashboardSharpIcon />
            <h3>Dashboard</h3>
          </a>
          <a href="#" className="inactive" onClick={handleLogout}>
            <h3>Logout</h3>
          </a>
        </div>
      </aside>
      <main>
        <h1>{dashboardTitle}</h1>
        <div className="insights">
          <div className="Online">
            <div className="middle">
              <div className="left">
                <h3>Online</h3>
                <h1 id="Online">{onlineCount}</h1>
              </div>
              <div className="icon">
                <span className="material-symbols-sharp"><OnlinePredictionSharpIcon /></span>
              </div>
            </div>
          </div>
          <div className="Available">
            <div className="middle">
              <div className="left">
                <h3>Available</h3>
                <h1 id="Available">{availableCount}</h1>
              </div>
              <div className="icon">
                <span className="material-symbols-sharp"><EventAvailableSharpIcon /></span>
              </div>
            </div>
          </div>
          <div className="Full">
            <div className="middle">
              <div className="left">
                <h3>Full</h3>
                <h1 id="Full">{fullCount}</h1>
              </div>
              <div className="icon">
                <span className="material-symbols-sharp"><EventBusySharpIcon /></span>
              </div>
            </div>
          </div>
          <div className="Maintenance">
            <div className="middle">
              <div className="left">
                <h3>Maintenance</h3>
                <h1 id="Maintenance">{maintenanceCount}</h1>
              </div>
              <div className="icon">
                <span className="material-symbols-sharp"><PendingActionsSharpIcon /></span>
              </div>
            </div>
          </div>
        </div>
        <h2>{userLoggedIn ? `${auth.currentUser.email}'s Bin` : 'My Bin'}</h2>
        {binExists ? (
          <div className={`mybin ${isLidOpen ? 'open' : 'closed'} ${!isOnline ? 'offline' : ''}`}>
            {isOnline !== undefined && (
              <div className="my_bin_title">
                <h1 className={isOnline ? '' : 'offline'}>{isOnline ? 'Online' : 'Offline'}</h1>
              </div>
            )}
            {isOnline !== undefined && (
              <div className="my_bin_last_online">
                <h1>Uptime: {uptime}</h1>
              </div>
            )}
            {isOnline && (
              <div className="range" style={{ '--p': capacity }}>
                <div className="range__label">Capacity: {capacity}%</div>
              </div>
            )}
          </div>
        ) : (
          <div className="setup-overlay">
            <h2>Please Setup your Bin first!</h2>
          </div>
        )}
      </main>
    </div>
  );
};

export default Home;
